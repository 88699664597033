export default {
  name: 'admin',
  component: () =>
    import(
      /* webpackChunkName: "Auth Layout" */ '@/modules/admin/layouts/AuthLayout.vue'
    ),
  children: [
    {
      path: 'subida-archivos',
      name: 'homeAdmin',
      meta: { title: "Subida de archivos" },
      component: () =>
        import(
          /* webpackChunkName: "Login View" */ '@/modules/admin/views/home/HomeAdminView.vue'
        ),
    },
    {
      path: 'letras-digitales',
      name: 'digitalLetters',
      meta: { title: "Letras digitales" },

      component: () =>
        import(
          /* webpackChunkName: "Login View" */ '@/modules/admin/views/letters/LettersAdminView.vue'
        ),
    },
    {
      path: 'clients',
      name: 'clients',
      meta: { title: "Clientes" },

      component: () =>
        import(
          /* webpackChunkName: "Login View" */ '@/modules/admin/views/clients/index.vue'
        ),
    },

    {
      path: ':pathMatch(.*)*',
      redirect: {
        name: 'homeAdmin',
      },
    },
  ],
};
