import { fetchWithToken } from '../../../helpers/fetch';
import { fetchGenerateToken, fetchGetHistory } from "@/helpers/fetchSentinel";

// change company
export const changeCompany = ({ commit }, { company }) => {
  commit('changeCompany', { company });
};

// Last Update: account status
export const getAccountLastUpdate = async ({ commit, state }) => {
  const data = {
    company: state.company,
  };
  const response = await (
    await fetchWithToken('/accountStatus/lastUpdate', data, 'POST')
  ).json();
  commit('setAccountStateLastUpdate', { accountState: response.message });
};
// user: user Update
export const getUserUpdate = async ({ email, cellphone, address, state }) => {
  const data = {
    email: email,
    cellphone: cellphone,
    address: address,
  };
  const response = await (
    await fetchWithToken(`/user/${state.id}`, data, 'PUT')
  ).json();
  commit('setUserUpdate', { userUpdate: response.message });
};
// user: user Show data
export const getDataUserShow = async ({ commit }) => {
  const data = {};
  const id = localStorage.getItem('x-id');

  const response = await (
    await fetchWithToken(`/user/${id}`, data, 'GET')
  ).json();
  commit('setShowUserData', { showUserData: response.message });
  localStorage.setItem('user', JSON.stringify(response.message));
};

export const getInfoUser = async ({ rootState }) => {
  const id = rootState.auth.user_id;

  try {
    let { status, message } = await (
      await fetchWithToken(`/user/${id}`)
    ).json();
    if (!status) status = '';
    const success = status === 'success';
    if (success) {
      return { success, user: message };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};

// Last Update: billing report
export const getBillingLastUpdate = async ({ commit, state }) => {
  const data = {
    company: state.company,
  };
  const response = await (
    await fetchWithToken('/billing/lastUpdate', data, 'POST')
  ).json();
  commit('setbillingReportLastUpdate', { billingState: response.message });
};

// data: account status
export const getAccountStatusData = async (
  { commit, state },
  { start_date, end_date, page }
) => {
  const data = {
    company: state.company || localStorage.getItem('company'),
  };
  if (start_date && end_date) {
    data.start_date = start_date;
    data.end_date = end_date;
  }

  const response = await (
    await fetchWithToken('/accountStatus/list?page=' + page + '&limit=15', data, 'POST')
  ).json();
  response.data.forEach(letter => {
    letter.isLoadingPDF = false;
  })
  commit('setAccountStatusData', { data: response });
  commit('setAccountLastPage', { lastPage: response.last_page ?? 1 });
  if (response.data) {
    const file = await fetchWithToken('/accountStatus/pdf', data, 'POST');
    const fileBlob = await file.blob();
    const fileUrl = URL.createObjectURL(fileBlob);

    const fileExcel = await fetchWithToken('/accountStatus/excel', data, 'POST');
    const fileBlobExcel = await fileExcel.blob();
    const fileUrlExcel = URL.createObjectURL(fileBlobExcel);

    commit('setAccountFileUrl', { fileUrl, fileUrlExcel });
  } else {
    commit('setAccountFileUrl', { fileUrl: '', fileUrlExcel: '' });
  }
};

// data: billing report
export const getBillingData = async (
  { commit, state },
  { start_date, end_date, page }
) => {
  const data = {
    company: state.company || localStorage.getItem('company'),
  };
  if (start_date && end_date) {
    data.start_date = start_date;
    data.end_date = end_date;
  }
  const response = await (
    await fetchWithToken('/billing/list?page=' + page + '&limit=15', data, 'POST')
  ).json();
  commit('setBillingData', { data: response });
  commit('setBillingLastPage', { lastPage: response.last_page ?? 1 });
  if (response?.data?.length) {
    const file = await fetchWithToken('/billing/excel', data, 'POST');
    const fileBlob = await file.blob();
    const fileUrl = URL.createObjectURL(fileBlob);
    commit('setBillingFileUrl', { fileUrl });
  } else {
    commit('setBillingFileUrl', { fileUrl: '' });
  }
};

// data: user and analist

export const getUserAndAnalistData = async () => {
  try {
    let { status, message, errors } = await (
      await fetchWithToken('/otherQuery/balanceEndYear', {}, 'POST')
    ).json();
    if (!status) status = '';

    const success = status === 'success';
    if (success) {
      let new_message = message;
      const isArrayMessage = Array.isArray(message);
      if (isArrayMessage) new_message = message.join(' ');
      return {
        success,
        message: new_message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: 'Hubo un problema, vuelva a intentarlo..',
    };
  }
};

export const callSeller = async ({ commit }) => {
  try {
    let { status, message, errors } = await (
      await fetchWithToken('/otherQuery/callSeller', {}, 'POST')
    ).json();
    if (!status) status = '';
    const success = status === 'success';

    if (success) {
      let new_message = message;
      const isArrayMessage = Array.isArray(message);
      if (isArrayMessage) new_message = message.join(' ');
      return {
        success,
        message: new_message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Hubo un problema, vuelva a intentarlo..',
    };
  }
};

export const userUpdate = async ({ state }, info) => {
  console.log(info)

  const user_id = state.userData.id;
  try {
    let { status, message, errors } = await (
      await fetchWithToken(`/user/${user_id}`, info, 'PUT')
    ).json();
    if (!status) status = '';
    const success = status === 'success';
    if (success) {
      let new_message = message;
      const isArrayMessage = Array.isArray(message);
      if (isArrayMessage) new_message = message.join(' ');
      return {
        success,
        message: new_message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: 'Hubo un problema, vuelva a intentarlo..',
    };
  }

  if (response.status === 'error') {
    return {
      success: false,
      message: response.message,
    };
  } else {
    return {
      success: true,
      message: 'Datos actualizaddos',
    };
  }
};

export const getCanceledDebt = async () => {
  try {
    const { status, message, errors } = await (
      await fetchWithToken('/charges/canceledDebt', {}, 'POST')
    ).json();
    const success = status === 'success';
    if (success) {
      let new_message = message;
      const isArrayMessage = Array.isArray(message);
      if (isArrayMessage) new_message = message.join(' ');
      return {
        success,
        message: new_message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: 'Hubo un problema, vuelva a intentarlo..',
    };
  }
};

export const getuprisingOfProtest = async (_, { formData }) => {
  try {
    let { status, message, errors } = await (
      await fetchWithToken('/charges/uprisingOfProtest', formData, 'POST', true)
    ).json();

    if (!status) status = '';

    const success = status === 'success';
    if (success) {
      let new_message = message;
      const isArrayMessage = Array.isArray(message);
      if (isArrayMessage) new_message = message.join(' ');
      return {
        success,
        message: new_message,
      };
    } else if (!success && !errors) {
      return {
        success,
        message,
      };
    } else if (errors && Object.values(errors).length > 0) {
      const errorList = Object.values(errors);

      return {
        success: false,
        message: errorList[0][0],
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: 'Hubo un problema, vuelva a intentarlo..',
    };
  }
};

export const getDocument = async ({ state }, { ruc, type, folio, typeDocumentValue, tipoEmpresa }) => {
  const formData = {
    ruc,
    tipoDoc: typeDocumentValue,
    folio,
    tipoRetorno: type,
    tipoEmpresa
  };
  const response = await (
    await fetchWithToken('/sovos', formData, 'POST')
  ).json();

  return response;
};

export const searchSentinelToken = async () => {
  return await fetchGenerateToken();
}

export const searchSentinel = async ({ }, { ruc, token }) => {
  return await fetchGetHistory({ ruc, token })
}

export const searchBehavior = async ({ commit, state }, { ruc }) => {
  const payload = {
    company: state.company || localStorage.getItem('company'),
    rucOrName: ruc,
  };
  const response = await fetchWithToken(
    "/paymentHistory/search",
    payload,
    "POST",
    false
  );

  const { paymentBehavior, score } = await response.json();
  commit('scoringScore', parseInt(score || 0))
  return paymentBehavior
};