/* export const myGetter = (state)=> { return state; } */

export const statesAccount = (state, payload) => {
    return state.statesAccount.search
}

export const paymentsHistory = (state, payload) => {
    return state.paymentsHistory.search
}
export const paymentsHistoryScore = (state) => {
    return state.paymentsHistory.score
}

export const modalInfoBehavior = (state, payload) => {
    return state.paymentsHistory.modals.infoBehavior
}

export const creditBureausSearch = (state, payload) => {
    return state.creditBureaus.search
}
export const creditBureausTrafficLight = (state, payload) => {
    return state.creditBureaus.trafficLight
}