export const getIpUser = async () => {
  try {
    const resp = await fetch('https://api.ipify.org/?format=json');
    const respJSON = await resp.json();

    return Object.values(respJSON) ? respJSON.ip : '1.1.1.1';
  } catch (error) {
    return '1.1.1.1';
  }
};
