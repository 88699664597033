/* export const myGetter = (state)=> { return state; } */
export const getInfoUser = (state) => {
  return state.userData;
};

export const getCompany = (state) => {
  return state.company;
};

export const getAccountState = (state) => {
  return state.billing.accountState
}

export const scoringSearch = (state) => {
  return state.scoring.search
}
export const scoringBehavior = (state) => {
  return state.scoring.behavior
}

export const scoringScore = (state) => {
  return state.scoring.score
}

export const scoringDebtDetail = (state) => {
  return state.scoring.debtDetail
}

export const scoringCharts = (state) => {
  return state.scoring.charts
}

export const scoringSeries = (state) => {
  return state.scoring.series
}