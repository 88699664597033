const baseApi = process.env.VUE_APP_API_FABER;

const getCommonHeaders = () => {
  let headers = {};

  headers['Content-Type'] = 'application/json';
  return headers;
};

const fetchGenerateToken = async () => {
  const url = `${baseApi}/experian/generate-token`;

  const headers = getCommonHeaders();

  const request = {
    method: "POST",
    headers,
  };


  try {
    const response = await fetch(url, request);
    if (!response.ok) {
      throw new Error(`Error al generar token: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error en fetchGenerateToken:', error);
    throw error;
  }
};


const fetchGetHistory = async ({ ruc, token }) => {
  const url = `${baseApi}/experian/get-history`;

  const body = JSON.stringify({ ruc, token });
  const headers = getCommonHeaders();

  const request = {
    method: "POST",
    headers,
    body,
  };

  try {
    const response = await fetch(url, request);
    if (!response.ok) {
      throw new Error(`Error al obtener historial: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error en fetchGetHistory:', error);
    throw error;
  }
};

export { fetchGenerateToken, fetchGetHistory };
