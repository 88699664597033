const baseUrl = process.env.VUE_APP_API_DEV;
const baseUrlApiDev = process.env.VUE_APP_API_PERU;

const fetchNotToken = (endpoint, data, method = "GET") => {
  const url = `${baseUrl}${endpoint}`;

  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        "Accept-Language": localStorage.getItem("locale") || "es"
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchWithToken = (endpoint, data, method = "GET", formData = false) => {
  const url = `${baseUrl}${endpoint}`;
  const token = localStorage.getItem("x-token") || "";

  if (method === "GET") {
    const headers = {
      Authorization: "Bearer " + token,
      "Accept-Language": localStorage.getItem("locale") || "es"
    };
    return fetch(url, {
      method,
      headers,
    });
  } else {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer  " + token,
      "Accept-Language": localStorage.getItem("locale") || "es"
    };

    let requestOptions = {
      method,
      headers,
      body: JSON.stringify(data),
    };

    if (formData === false && Object.keys(data).length === 0)
      delete requestOptions.body;

    if (formData) {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      delete requestOptions.headers;
      delete requestOptions.body;

      requestOptions.headers = myHeaders;
      requestOptions.body = data;
    }

    return fetch(url, requestOptions);
  }
};

const fetchApiDevWithToken = (endpoint, method = "GET") => {
  let token = process.env.VUE_APP_API_PERU_TOKEN;
  const url = `${baseUrlApiDev}${endpoint}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    "Accept-Language": localStorage.getItem("locale") || "es"
  };
  return fetch(url, {
    method,
    headers,
  });
};

export { fetchNotToken, fetchWithToken, fetchApiDevWithToken };
