export default {
  name: 'marketing',
  component: () =>
    import(
       /* webpackChunkName: "Auth Layout" */ '@/modules/admin/layouts/AuthLayout.vue'
    ),
  children: [
    {
      path: 'estados-cuenta',
      name: 'homeMarketing',
      meta: { title: 'Estados de Cuenta' },
      component: () =>
        import(
          /* webpackChunkName: "Login View" */ '@/modules/marketing/views/home/HomeMarketing.vue'
        ),
    },
    {
      path: 'historial-pagos',
      name: 'historyPayments',
      meta: { title: 'Historial de Pagos' },
      component: () =>
        import(
          /* webpackChunkName: "Login View" */ '@/modules/marketing/views/historical/HistoricalPayments.vue'
        ),
    },
    {
      path: 'centrales-de-riesgo',
      name: 'creditBeraus',
      meta: { title: 'Centrales de Riesgos' },
      component: () =>
        import(
          /* webpackChunkName: "Credit Bureaus View" */ '@/modules/marketing/views/creditBureaus/CreditBureaus.vue'
        ),
    },
    {
      path: ':pathMatch(.*)*',
      redirect: {
        name: 'homeMarketing',
      },
    },
  ],
};
