export default () => ({
  statesAccount: {
    search: {
      rucOrName: "",
      isLoading: false,
      data: {
        data: []
      },
      select: {},
      fileUrlExcel: null,
      fileUrl: null
    },
  },
  paymentsHistory: {
    search: {
      rucOrName: "",
      isLoading: false,
      data: {}
    },
    modals: {
      infoBehavior: {
        isOpen: false,
        selected: null,
        data: {}
      },
    },
    score: ''
  },
  creditBureaus: {
    search: {
      isLoading: false,
      ruc: "",
      response: {},
    },
    trafficLight: {
      data: {},
      charts: {},
      series: [],
      debtDetail: [],
    },
  }
});
