export const changeCompany = (state, { company }) => {
  localStorage.setItem('company', company);
  state.company = company;
};

export const setAccountStateLastUpdate = (state, { accountState }) => {
  state.billing.accountState.lastUpdate = accountState;
};

export const setbillingReportLastUpdate = (state, { billingState }) => {
  state.billing.billingReport.lastUpdate = billingState;
};

export const setAccountStatusData = (state, { data }) => {
  state.billing.accountState.data = data;
};

export const setBillingData = (state, { data }) => {
  state.billing.billingReport.data = data;
};

export const setAccountFileUrl = (state, { fileUrl, fileUrlExcel }) => {
  state.billing.accountState.fileUrl = fileUrl;
  state.billing.accountState.fileUrlExcel = fileUrlExcel;

};

export const setBillingFileUrl = (state, { fileUrl }) => {
  state.billing.billingReport.fileUrl = fileUrl;
};

export const setAccountLastPage = (state, { lastPage }) => {
  state.billing.accountState.lastPage = lastPage;
};

export const setBillingLastPage = (state, { lastPage }) => {
  state.billing.billingReport.lastPage = lastPage;
};

export const setuserData = (state, { userData }) => {
  state.userData = userData;
};

export const setShowUserData = (state, { showUserData }) => {
  state.showUserData = showUserData;
  console.log(state.showUserData)
};

export const setAnalistData = (state, { analistData }) => {
  state.analistData = analistData;
};

export const setCharge = (state, { charge }) => {
  state.charge = charge;
};


export const scoringSearch = (state, payload) => {
  state.scoring.search = {
    ...state.scoring.search,
    ...payload
  };
}

export const scoringScore = (state, payload) => {
  state.scoring.score = payload;
}

export const scoringBehavior = (state, payload) => {
  state.scoring.behavior = {
    ...state.scoring.behavior,
    ...payload
  };
}

export const scoringDebtDetail = (state, { data }) => {
  state.scoring.debtDetail = data;
}

export const setChartsAndSeries = (state, { series, charts }) => {
  state.scoring.charts = charts;
  state.scoring.series = series;
}