export default () => ({
  digitalLetters: {
    search: {
      rucOrName: "",
      isLoading: false,
      data: [],
      select: {}
    },

  },

});
