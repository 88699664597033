/* export const myMutation = (state)=> {} */

export const statesAccount = (state, payload) => {
    state.statesAccount.search = {
        ...state.statesAccount.search,
        ...payload
    };
}

export const paymentsHistory = (state, payload) => {
    state.paymentsHistory.search = {
        ...state.paymentsHistory.search,
        ...payload
    };
}

export const modalInfoBehavior = (state, payload) => {
    state.paymentsHistory.modals.infoBehavior = payload;
}

export const creditBureausSearch = (state, payload) => {
    state.creditBureaus.search = {
        ...state.creditBureaus.search,
        ...payload
    };
}

export const creditBureausTrafficLight = (state, payload) => {
    state.creditBureaus.trafficLight = {
        ...state.creditBureaus.trafficLight,
        ...payload
    };
}

export const historyScore = (state, payload) => {
    state.paymentsHistory.score = payload;
}


