import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/styles.scss";
import "./modules/auth/styles/styles.scss";
import "vue3-carousel/dist/carousel.css";
import "vue3-toastify/dist/index.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "./utils/ga/ga";
import Vue3Toastify from "vue3-toastify";
import gaPage from "./utils/ga/gaPage";
import { createI18n } from "vue-i18n";
import es from "./lang/es.json";
import en from "./lang/en.json";

import VueApexCharts from "vue3-apexcharts";
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'

const i18n = createI18n({
    locale: localStorage.getItem("locale") || "es",
    messages: {
        es,
        en,
    },
});

createApp(App)
    .component("Carousel", Carousel)
    .component("Slide", Slide)
    .component("Navigation", Navigation)
    .use(Vue3Toastify, { autoClose: 3000 })
    .use(store)
    .use(gaPage)
    .use(router)
    .use(i18n)
    .use(VueApexCharts)
    .use(VueCustomTooltip)
    .mount("#app");
