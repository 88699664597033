export default () => ({
  team: 'Codekillers',
  company: '',
  userData: JSON.parse(localStorage.getItem('x-user')) || {},
  analistData: {},
  charge: {},
  billing: {
    accountState: {
      lastPage: 1,
      lastUpdate: '',
      data: {},
      fileUrl: '',
      fileUrlExcel: ''
    },
    billingReport: {
      lastPage: 1,
      lastUpdate: '',
      data: {},
      fileUrl: '',
    }
  },

  scoring: {
    search: {
      isLoading: false,
      ruc: "",
      response: {},
    },
    behavior: {
      isOpen: false,
      selected: null,
      data: {},
      current: {}
    },
    score: null,
    debtDetail: [
    ],
    charts: {},
    series: []
  },
});
