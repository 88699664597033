import { fetchWithToken } from "@/helpers/fetch";
import { fetchGenerateToken, fetchGetHistory } from "@/helpers/fetchSentinel";

export const search = async ({ commit }, { payload, page, type }) => {
  commit(type, { isLoading: true });
  let response = null;
  const file = await fetchWithToken(
    "/accountStatus/pdf/marketing",
    payload,
    "POST"
  );
  let fileUrl = null;
  if (file.status !== 404) {
    const fileBlob = await file.blob();
    fileUrl = URL.createObjectURL(fileBlob);
  }

  const fileExcel = await fetchWithToken(
    "/accountStatus/excel/marketing",
    payload,
    "POST"
  );
  let fileUrlExcel = null;
  if (fileExcel.status !== 404) {
    const fileBlobExcel = await fileExcel.blob();
    fileUrlExcel = URL.createObjectURL(fileBlobExcel);
  }

  commit("statesAccount", { fileUrl, fileUrlExcel });

  if (type === "statesAccount") {
    response = await await fetchWithToken(
      "/accountStatus/list/marketing?page=" + page + "&limit=15",
      payload,
      "POST",
      false
    );
  } else {
    response = await fetchWithToken(
      "/paymentHistory/search",
      payload,
      "POST",
      false
    );
  }
  const { score, ...data } = await response.json();
  if (score) {
    commit('historyScore', parseInt(score));
  }
  if (Array.isArray(data)) commit(type, { data: {}, isLoading: false });
  else commit(type, { data: { ...data }, isLoading: false });
};

export const searchSentinelToken = async () => {
  return await fetchGenerateToken();
}

export const searchSentinel = async ({ }, { ruc, token }) => {
  return await fetchGetHistory({ ruc, token })
}

export const getDocuments = async ({ commit }, { payload }) => { };


export const searchBehavior = async ({ commit, state }, { ruc }) => {
  const payload = {
    company: state.company || localStorage.getItem('company'),
    rucOrName: ruc,
  };
  const response = await fetchWithToken(
    "/paymentHistory/search",
    payload,
    "POST",
    false
  );

  const { paymentBehavior } = await response.json();
  return paymentBehavior
};