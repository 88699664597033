window.onload = (event) => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-249712648-1');
    gtag('event', 'page_view', {
        page_title: '<Page Title>',
        page_location: window.location,
        page_path: '<Page Path>',
        send_to: '<GA_MEASUREMENT_ID>'
    })
    console.log("ga page")
}
window.onhashchange = function() {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-249712648-1');
    gtag('event', 'page_view', {
        page_title: document.URL,
        page_location: document.URL,
        page_path: '<Page Path>',
        send_to: '<GA_MEASUREMENT_ID>'
    })
    console.log("ga page test")
}